define("discourse/plugins/abbreviations/lib/discourse-markdown/abbreviation", ["exports", "pretty-text/pretty-text"], function (_exports, _prettyText) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setup = setup;
  (0, _prettyText.registerOption)(function (siteSettings, opts) {
    opts.features.abbreviation = !!siteSettings.abbreviations_plugin_enabled;
    opts.abbreviationList = siteSettings.abbreviations_plugin_list;
  });

  function abbreviate(text, list) {
    var abbreviationCouples = list.split("|").map(function (couple) {
      return couple.split(":");
    });
    abbreviationCouples.forEach(function (couple) {
      //required a space either before or after the acronym
      text = text.replace(new RegExp("\\b((\\s)" + couple[0] + "|" + couple[0] + "(?=\\s))\\b", "g"), '$2<abbr title="' + couple[1] + '">' + couple[0] + '</abbr>');
    });
    return text;
  }

  ;

  function setup(helper) {
    helper.addPreProcessor(function (text) {
      return abbreviate(text, helper.getOptions().abbreviationList);
    });
  }
});

